import $ from "jquery";
import 'slick-carousel';
import 'magnific-popup';

$(document).ready(function () {
  $('.main-slider').slick({
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: false,
    dots: true,
    customPaging: function (slider, i) {
      return '<span class="slick-dot"></span>';
    },
  });

  $('.js-nav-open').click(function () {
    $('.site-navigation').addClass('toggled');
  });

  $('.js-nav-close').click(function() {
    $('.site-navigation').removeClass('toggled');
  });
  
  $(window).resize(function () {
    if($(window).innerWidth() >= 992) {
      $('.site-navigation').removeClass('toggled');
    }
  });
  
  $('.text__content .toggled').slideToggle();

  $('.text__content .text__more').click(function() {
    $(this).parent().children('.toggled').slideToggle();
    $(this).remove();
  });

  if ($('.certificates-list').length > 0) {
    $('.certificates-list').magnificPopup({
      delegate: 'a',
      type: 'image'
    })
  }

  if ($('.brands-list_slider').length > 0) {
    $('.brands-list_slider').slick({
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      prevArrow: '<span class="icon-arrow-left"></span>',
      nextArrow: '<span class="icon-arrow-right"></span>',
      responsive: [{
          breakpoint: 576,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 5
          }
        }
      ]
    });
  }
});